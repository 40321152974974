 
.form-input {
    border: 1 solid #0373fc;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
    color: #333
}
 

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.invalid-validate{
  color: red;
  margin-top: 4px;
  font-size: 13px;
}