.footer {
  background: $footer-bg;
  color: $footer-color;
  border-top: none;
  padding: 20px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type1;
  font-weight: 400;
  a,p {
    // color: theme-color(success);
    color:#FFFFFF;
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}
